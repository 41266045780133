import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { device } from "../utils/devices"
import Layout from "../components/layout"
import cuff from "../images/cuff.jpg"
import ReactPlayer from "react-player"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  padding: 0 5vw;

  p {
    max-width: 800px;
  }

  h2 {
    text-transform: uppercase;
    margin: 0 0 2rem 0;
  }

  .video-player {
    width: 100% !important;
    height: 47vh !important;

    @media ${device.laptop} {
      width: 50vw !important;
      height: 60vh !important;
    }
  }
`

const List = styled.div`
  margin-bottom: 2rem;
  text-align: ${props => (props.center ? `center` : `left`)};

  p {
    margin: 0 0 1rem 0;
  }

  div {
    display: flex;
    /* flex-direction: column; */
    margin-bottom: 1rem;

    span {
      margin-right: 1rem;
    }
  }
`

const HowToPlay = () => (
  <Layout>
    <SEO title="How to Play | Make it Make Sense">
      <title>How to Play | Make it Make Sense</title>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta property="type" content="website" />
      <meta property="url" content="https://mims.live/how-to-play" />
      <meta name="msapplication-TileColor" content="#30a4d5" />
      <meta name="theme-color" content="#30a4d5" />
      <meta property="title" content="Make it Make Sense" />
      <meta
        name="description"
        content="Trap Karaoke presents “Make It Make Sense” (MIMS), the live game show where you can win ca$h for your captions🤑."
      />
      <meta property="image" content={cuff} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Make it Make Sense" />
      <meta property="og:hashtag" content="MakeItMakeSense" />
      <meta property="og:image" content={cuff} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content="https://mims.live/how-to-play" />
      <meta property="og:site_name" content="TrapKaraoke" />
      <meta
        property="og:description"
        content="Trap Karaoke presents “Make It Make Sense” (MIMS), the live game show where you can win ca$h for your captions🤑."
      />
    </SEO>
    <Wrapper>
      <h2>How to Play</h2>
      <List center>
        <ReactPlayer
          className="video-player"
          controls
          url="https://vimeo.com/627098985"
        />
      </List>
      <List center>
        <p>
          Can you make it make sense? No, not this year — these memes! Trap
          Karaoke presents “Make It Make Sense” (MIMS), the live game show where
          you can win ca$h for your captions 🤑.
        </p>
      </List>
      <List>
        <div>How to Play:</div>
        <div>
          <span>1️⃣</span>Host presents a meme
        </div>
        <div>
          <span>2️⃣</span> You 2 minutes to caption it. Make it funny; make it
          relatable; make it make sense!
        </div>
        <div>
          <span>3️⃣</span> The audience votes for the winning caption
        </div>
        <div>
          <span>4️⃣</span> Winner of each round gets a cash prize (10 rounds
          total)
        </div>
      </List>
      <List center>
        <p>You can play 💬</p>
        <p>You can watch 📺</p>
        <p>You can win 💰</p>
        <br />
        <p>See you in the comments 👀</p>
      </List>
    </Wrapper>
  </Layout>
)

export default HowToPlay
